export default {
  navbar: {
    item1: "Home",

    item2: "Conference schedule",

    item3: "Case collection",

    item4: "Previous reviews",

    item5: "Ticket",

    item6: "Authentication query"
    // item6: "China",
  },

  right: {
    item1: "News information",

    item2: "Conference Guests",

    item3: "About the organizer",

    item4: "View Tickets",

    item5: "Speech Collection",
    item6: "News",
    item7: "Current location: News consulting >",
    item8: "The Conference Producer",
  },

  bottom: {
    item1: {
      item1: "Ticket Consultation",

      item2: "Ms. Wang",

      item3: "17395027026",

      item4: "wangxt@spichina.org.cn",
      relationPhone: "智小二 15311230903",
      relationPhone0: "智小妹 15313600903",
      relationPhone1: "智小妹 15313600903",
    },

    // item2: {
    // 	item1: "Business sponsorship",

    // 	item2: "Ms. Wang",

    // 	item3: "17395027026",

    // 	item4: " wangxt@spichina.org.cn ",

    // 	item5: "Contact us",
    // },

    item3: {
      item1: "Speech Consultation",

      item2: "Ms. Wang",

      item3: "17395027026",

      item4: "wangxt@spichina.org.cn ",

      item5: "Video Case",
    },

    item3: {
      item1: "Speech Consultation",

      item2: "Ms. Wang",

      item3: "17395027026",

      item4: " wangxt@spichina.org.cn ",

      item5: "Video Case",
    },

    item4: "Zhongguancun Zhilian",

    item5: "Zhongguancun Zhilian",
    item6: "Friendly link",
    item7: "https://www.iqalliance.org.cn/sy",
    // item8: "details ",
    // wangxt@spichina.org.cn

    //Friendly link:
    item8: "xiangqingxzixun",
    item9: "goupiaozhida",
  },

  page1: {
    content1: "Presidium of the Conference	",

    content2: "Conference Agenda ",
    content2_1: "View all",
    content3: "ExecutiveProducer of the Conference	",

    content4: "Community Partners",

    content5: "Gold Sponsors",

    content6: "Platinum Sponsor",

    content7: "sponsor",

    content8: "Exclusive financial media",

    content9: "Media Partners",
    dhsj: "time",
  },
  page2: {
    rcap: "Schedule",
    dhrc: "Conference Agenda",
    time1: "time",
    time2: "schedule",
    time3: "producer",
  },
  page3: {
    tit1: "TiD Quality",

    tit1_1: "Competitiveness Conference",

    tit2: "Speech Collection",

    item1: {
      item1: "Selection criteria",

      content: "This software development excellent case will be collected around 'new quality productivity', aiming to explore and share 'excellent new industry cases', 'excellent new model cases', 'excellent new momentum cases' in the fields of software development testing, agility, efficiency, measurement, process improvement, digital transformation, etc. The specific content is as follows:",

      item2:
        "Excellent New Industry Cases: Showcasing excellent cases of software research and management in new industries, including but not limited to intelligent manufacturing, open source, new energy vehicles, digital finance, robotics, and other industries. The content can cover the entire process from basic research and development to industry applications, demonstrating how software research and development can promote industrial upgrading and innovation drive in strategic emerging industries and future industries.",

      item3:
        "Excellent New Mode Case: Showcasing innovative production and management methods in the software development process, as well as excellent cases on how to promote production mode innovation, management, and optimization, including but not limited to how to systematize process improvement, agility, R&D efficiency, and development toolchain management.",
      item4: "Excellent New Momentum Case: Through technological breakthroughs, software platform innovation, intelligent production and other means, demonstrate how the new momentum centered on 'artificial intelligence+' and 'new ecological software applications' supports the cultivation and development of software and related industries. This can involve innovative practices in areas such as intelligent testing, AI industry applications, AI evaluation, AI security, operating systems, etc."
    },

    item2: {
      item1: "Online submission",

      item2: "Organizational Preliminary Review",

      item3: "Organizational meeting final review",

      item4: "Online submission",
    },

    item3: "Submit materials",
  },

  contr: {
    tit1: "TiD Quality Competitiveness Conference",

    tit1_1: "Intelligent New Era Technology Wins the Future",

    tit2: "The Presidium of the Conference",

    item1: " The conference producer",
    item2: "details",
  },

  contributor: {
    tit1: "TiD Quality Competitiveness Conference",

    tit1_1: "Intelligent New Era Technology Wins the Future",

    tit2: "Speaker",

    item1: "Speaker",
  },
  sponsor: {
    tit1: "TiD Quality Competitiveness Conference",
    tit1_1: "Intelligent New Era Technology Wins the Future",
    tit2: "About the organizer",
    item1: "About the organizer",
    item2:
      "Zhongguancun Zhilian Software Service Industry Quality Innovation Alliance",
    item3:
      "Zhongguancun Zhilian Software Service Industry Quality Innovation Alliance (hereinafter referred to as Zhilian , English IQ alliance), with the mission of forming a seven party alliance and win-win ecology (seven parties refer to government, enterprises, academia, associations, consulting, community, and users), surrounds the Innovation Leadership Plan and Quality Competitiveness Plan in the software service industry Carry out work on two major strategic plans. I/Q plan, supporting each other and combining two swords, enhances the intelligence of enterprises and helps them become stronger. The Zhilian Alliance adheres to the principle of putting innovation first and actively carries out technological innovation and standard creation under the framework of the I/Q plan. Major achievements include the industry standard Software R&D Cost Measurement Specification, which has been widely used in fields such as banking, insurance, customs, national taxation, judiciary, military, etc., to solve key problems that have long constrained industrial development, such as undervaluation of software value, difficulty in negotiating software bidding, and lack of basis for software budgets. In its own development process, the Zhilian Alliance has explored a series of unique experiences and formed an overall development policy of mission based, community based, alliance based, and innovation oriented Adhere to the alliance principle of serving members wholeheartedly. Forming a benign market-oriented model for innovative achievements, with strong sustainable development capabilities, we are making unremitting efforts to increase the intelligence of enterprises, help them become stronger, and achieve a win-win situation through ecological integration.",
  },
  ticket: {
    tit1: "TiD Quality Competitiveness Conference",
    tit2: "View Ticketing",
    tit3: "Zhongguancun Zhilian Software Service Industry Quality Innovation Alliance",
    item1: "Price List",
    item2: {
      item1: "A vote",
      item2: "B vote",
      item3: "C vote",
    },
    mpItem: {
      item1: "Deadline",

      item2: "Before May 31st",

      item3: "Before June 30th",

      item4: "Before the meeting",
    },

    mp: "Conference tickets",

    qy: "Ticket Rights",

    gp: "Attending and purchasing tickets",

    item1: "Price List",

    course1: "Large period parallel courses",

    course1_time: "(August 13-14)",

    course2: "General Meeting",

    course2_time: "(August 15th morning)",

    course3: "Special Session",

    course3_time: "(August 15th, afternoon to 16th)",
    row: {
      // item1: "Large period parallel courses on September 5th",
      // item2: "September 6th parallel course for large periods",
      // item3: "September 7th Plenary Meeting",
      // item4: "September 8th parallel symposium",
      // item5: "September 9th Parallel Symposium",
      item1: "August 13th",
      item2: "August 14th",
      item3: "August 15th",
      item4: "August 16th",
      // item5: "August 17th",
    },
    item3: {
      item1: "Ticket purchase instructions",

      text1:
        "September 5-6 is a parallel large period course; September 7 is a plenary meeting; September 8-9 is a parallel special session",

      item2: "Group purchase discount",

      text2:
        "Buy four and get one free (if the four tickets have different prices, the ticket with the lowest price will be given as a gift)",

      item3: "Ticket purchase method",

      text3:
        "① Transfer ticket purchase: Beijing Aite Industrial and Social Technology Co., Ltd. 1100 1125 7000 5251 7948 China Construction Bank Beijing Zhongguancun Software Park Branch",

      text3_1:
        "② Online ticket purchase: Scan the QR code below to purchase tickets",

      item4: "Ticket Consultation",
      item5: "pay",
    },
  },
  DetailDialog: {
    item1: "Forum Name：",
    item2: "venue：",
    item3: "Sharingtime",
    item4: "course guideline",
    item5: "Highlights",
    item6: "Speaker",
  },
  producerDialog: {
    item1: "name",
    item2: "COMPANY",
    item3: "company",
    item4: "introduce",
  },
  news: {
    item8: "news",
    item9: "all previous",
  },
  previous: {
    item1: "Development History",
    item2:
      "The Zhongguancun Zhilian Software Service Industry Quality Innovation Alliance coincides with a critical turning point for China's software service industry to become stronger and stronger",
    item3:
      "Technologies such as cloud and mobile intelligence have brought new opportunities for change, effectively addressing the major challenges of quality and innovation in the process of industrial strength. On November 8, 2013, the Zhilian Alliance held talks with relevant government departments such as Zhongguancun Management Committee and Haidian Garden Management Committee",
    item4:
      "Technologies such as cloud and mobile intelligence have brought new opportunities for change, effectively addressing the major challenges of quality and innovation in the process of industrial strength. On November 8, 2013, the Zhilian Alliance held talks with relevant government departments such as Zhongguancun Management Committee and Haidian Garden Management Committee",
  },
};
