import { render, staticRenderFns } from "./footerCom.vue?vue&type=template&id=5d204086&scoped=true"
import script from "./footerCom.vue?vue&type=script&lang=js"
export * from "./footerCom.vue?vue&type=script&lang=js"
import style0 from "./footerCom.vue?vue&type=style&index=0&id=5d204086&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d204086",
  null
  
)

export default component.exports