import Vue from "vue";
import VueRouter from "vue-router";

import first from "../components/first.vue";
import Home from "../components/Home.vue";
import Welcom from "../components/Welcom.vue";
import Users from "../components/User/Users.vue";
import Role from "../components/Role/role.vue";
import List from "../components/List/list.vue";
import Dict from "../components/Dict/dict.vue";
import Details from "../components/Details.vue";
import Collect from "../components/Collect.vue";
import lecture from "../components/lecture.vue";
import contributor from "../components/contributor.vue";
import sponsor from "../components/sponsor.vue";
import ticket from "../components/ticket.vue";
import producer from "../components/producer.vue";
import contr from "../components/contr.vue";
import news from "../components/news.vue";
import newsn from "../components/newsn.vue";
import previous from "../components/previous.vue";

Vue.use(VueRouter);

// Vue.use(Router);

const routes = [

	{
		path: "/",
		name: "Home",
		//redirect: "/Welcom",
		component: () => import("../components/first.vue"),
		meta: { title: "系统", icon: "el-icon-s-home" },
	},

	{
		path: "/login",
		name: "Home",
		redirect: "/first",
	},
	{
		path: "/first",
		name: "first",
		component: () => import("../components/first.vue"),
		meta: { title: "首页", icon: "el-icon-s-home" },
	},
	{
		path: "/news",
		name: "news",
		component: () => import("../components/news.vue"),
		meta: { title: "首页", icon: "el-icon-s-home" },
	},
	{
		path: "/previous",
		name: "previous",
		component: () => import("../components/previous.vue"),
		meta: { title: "首页", icon: "el-icon-s-home" },
	},
	{
		path: "/newsn",
		name: "newsn",
		component: () => import("../components/newsn.vue"),
		meta: { title: "首页", icon: "el-icon-s-home" },
	},
	{
		path: "/contr",
		name: "contr",
		component: () => import("../components/contr.vue"),
		meta: { title: "首页", icon: "el-icon-s-home" },
	},
	{
		path: "/Details",
		name: "Details",
		component: () => import("../components/Details.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},
	{
		path: "/producer",
		name: "producer",
		component: () => import("../components/producer.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},
	{
		path: "/Collect",
		name: "Collect",
		component: () => import("../components/Collect.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},

	{
		path: "/lecture",
		name: "lecture",
		component: () => import("../components/lecture.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},

	{
		path: "/sponsor",
		name: "sponsor",
		component: () => import("../components/sponsor.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},

	{
		path: "/ticket",
		name: "ticket",
		component: () => import("../components/ticket.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},

	{
		path: "/contributor",
		name: "contributor",
		component: () => import("../components/contributor.vue"),
		meta: { title: "详情", icon: "el-icon-s-home" },
	},

	{
		path: "/Welcom",
		name: "Welcom",
		component: () => import("../components/Welcom.vue"),
		meta: { title: "首页", icon: "el-icon-s-home" },
	},

	{
		path: "/User",
		name: "Users",
		component: () => import("../components/User/Users.vue"),
		meta: { title: "用户管理", icon: "el-icon-s-home" },
	},
	{
		path: "/Role",
		name: "role",
		component: () => import("../components/Role/role.vue"),
		meta: { title: "角色管理", icon: "el-icon-s-home" },
	},
	{
		path: "/List",
		name: "list",
		component: () => import("../components/List/list.vue"),
		meta: { title: "账户管理", icon: "el-icon-s-home" },
	},
	{
		path: "/Dict",
		name: "dict",
		component: () => import("../components/Dict/dict.vue"),
		meta: { title: "字典表管理", icon: "el-icon-s-home" },
	},
	{
		path: "/home",
		name: "Home",
		//redirect: "/Welcom",
		component: () => import("../components/Home.vue"),
		meta: { title: "系统", icon: "el-icon-s-home" },
	},
  {
		path: "/authQuery",
		name: "authQuery",
		//redirect: "/Welcom",
		component: () => import("../components/authQuery.vue"),
		meta: { title: "认证查询", icon: "el-icon-s-home" },
	},
];

const router = new VueRouter({
	routes,
});


export default router;
