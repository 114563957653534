<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name:'app'
}
</script>

<style lang="less">
.header {
  z-index: 666 !important;
}
.first_pos {
  width: auto !important;
}

.foot_imgs {
  display: flex;
  justify-content: center !important;
  margin-left: -160px;
}
.foot_info {
  display: flex;
  justify-content: center !important;
  margin-left: -160px;
}
</style>
