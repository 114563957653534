export default {
  navbar: {
    item1: "首页",
    item2: "大会日程",
    item3: "案例征集",
    item4: "历届回顾",
    item5: "购票",
    item6: "证书查询",
    // item6:"中文"
  },
  right: {
    item1: "新闻动态",
    item2: "演讲嘉宾",
    item3: "关于主办方",
    item4: "查看票务",
    item5: "案例征集",
    item6: "新闻动态",
    item7: "当前位置 : 新闻咨询 >",
    item8: "大会出品人",
  },
  bottom: {
    item1: {
      item1: "票务咨询/商务赞助/演讲咨询",
      item2: "黄女士", // Ms. Wang
      item3: "18410933934", // 17395027026
      item4: "tid@spichina.org.cn ", //  huangjia@spichina.org.cn
      ticket: "票务咨询",
      // relationPhone: "苏女士 18800164634(票务咨询)",
      // relationPhone0: "魏女士 18701563098(票务咨询)",
      // relationPhone1: "魏女士 18701563098(票务咨询)", // (商务赞助 | 演讲咨询)
      relationPhone: "智小二 15311230903",
      relationPhone0: "智小妹 15313600903",
      relationPhone1: "智小妹 15313600903",
    },

    item4: "TiD 大会",
    item5: "中关村智联",
    item6: "友情链接",
    item7: "https://www.iqalliance.org.cn/sy",
    item8: "详情咨询",
    item9: "购票直达",
  },
  page1: {
    content1: "大会主席团",
    content2: "大会日程",
    content2_1: "查看所有",
    content3: "大会出品人",
    content6: "铂金赞助商",
    content4: "合作社区",
    content5: "黄金赞助商",

    content7: "赞助商",
    content8: "独家金融媒体",
    content9: "合作媒体",
    dhsj: "大会时间",
  },
  page2: {
    rcap: "日程安排",
    dhrc: "大会日程",
    time1: "时间",
    time2: "日程",
    time3: "出品人",
  },
  page3: {
    tit1: "TiD质量",
    tit1_1: "竞争力大会",
    tit2: "案例征集",
    item1: {
      item1: "征集方向",
      content:
        "本次软件研发优秀案例将围绕“新质生产力”展开征集工作，旨在挖掘和分享软件研发测试、敏捷、效能、度量、过程改进、数字化转型等领域的“优秀新产业案例”、“优秀新模式案例”、“优秀新动能案例”，具体内容如下：",
      item2: `1、优秀新产业案例：展示在新产业领域软件研发及管理的优秀案例，包括但不限于智能制造、开源、新能源汽车、数字金融、机器人等产业领域，内容可涵盖从基础研发到行业应用的全过程，展现软件研发如何在战略性新兴产业和未来产业领域促进产业升级和创新驱动。`,
      item3:
        "2、优秀新模式案例：展示软件研发过程中的创新生产方式、管理方式，如何推动生产模式创新、管理与优化的优秀案例，包括但不限于如何体系化过程改进、敏捷、研发效能、开发工具链管理等",
      item4:
        "3、优秀新动能案例：通过技术突破、软件平台创新、智能化生产等手段，展示以“人工智能+”“新生态软件应用”为核心的新动能如何支持培育和发展软件及相关产业的优秀案例，可涉及如智能化测试、AI行业应用、AI评测、AI安全、操作系统等方面的创新实践。",
    },
    item2: {
      item1: "在线提交",
      item2: "组织初审",
      item3: "组织会终审",
      item4: "在线提交",
    },
    item3: "提交资料",
  },
  contr: {
    tit1: "TiD质量竞争力大会",
    tit1_1: "智能新时代 科技赢未来",
    tit2: "新闻动态",
    item1: "大会出品人",
    item2: "详情",
  },
  contributor: {
    tit1: "TiD质量竞争力大会",
    tit1_1: "智能新时代 科技赢未来",
    tit2: "演讲嘉宾",
    item1: "演讲嘉宾",
  },
  sponsor: {
    tit1: "TiD质量竞争力大会",
    tit1_1: "智能新时代 科技赢未来",
    tit2: "关于主办方",
    item1: "关于主办方",
    item2: "中关村智联软件服务业质量创新联盟",
    item3:
      "中关村智联软件服务业质量创新联盟（以下简称“智联”，英文IQ alliance），以智结七方，联赢生态为使命（七方指政府、企业、学术、协会、咨询、社区、用户），在软件服务业领域围绕“创新领导力计划（Innovation 计划,简称I 计划）”、“质量竞争力计划（Quality 计划，简称Q 计划）”两大战略计划开展工作。I/Q 计划，互为支撑，双剑合璧，为企业增智，助企业做强。 智联联盟坚持“以创新为要”，在 I/Q 计划框架下积极开展技术创新和标准创制，重大成果包括行业标准《软件研发成本度量规范》，已被广泛用于银行、保险、海关、国税、司法、军队等领域，解决软件价值低估、软件招投标难以议价、软件预算缺乏依据等长期制约产业发展的关键问题。在自身发展过程中，智联联盟摸索出了一系列独特的经验，形成了“使命为根、社区为本、联盟为台、创新为要”总体发展方针；坚持“全心全意为会员服务”的联盟宗旨。形成良性的创新成果市场化模式，具有很强的可持续发展能力，正以不懈努力为企业增智、助企业做强，联生态共赢。",
  },
  ticket: {
    tit1: "TiD质量竞争力大会",
    tit2: "查看票务",
    tit3: "中关村智联软件服务业质量创新联盟",
    mpItem: {
      item1: "截止时间",
      item2: "5月31日前",
      item3: "6月30日前",
      item4: "会前",
    },
    mp: "大会门票",
    qy: "门票权益",
    gp: "参会购票",
    item1: "价目表",
    item2: {
      item1: "A票",
      item2: "B票",
      item3: "C票",
    },
    course1: "大时段并行课程",
    course1_time: "(8月13日-14日)",
    course2: "全体大会",
    course2_time: "(8月15日上午)",
    course3: "专题会",
    course3_time: "(8月15日下午-16日)",
    row: {
      // item1:"8月13日大时段并行课程",
      // item2:"8月14日大时段并行课程",
      // item3:"8月15日全体大会",
      // item4:"8月16日并行专题会",
      // item5:"8月17日并行专题会",
      item1: "8月13日",
      item2: "8月14日",
      item3: "8月15日",
      item4: "8月16日",
      // item5:"8月17日",
    },
    item3: {
      item1: "购票说明",
      text1:
        "8月13日-14日为并行大时段课程；8月15日上午为全体会议；8月15下午-16日为并行专题会",
      item2: "团购优惠",
      text2: "买四赠一（若四张票价不同，则赠送票价最低的票）",
      item3: "购票方式",
      text3:
        "① 转账购票： 北京艾特工社科技有限公司 1100 1125 7000 5251 7948 建行北京中关村软件园支行",
      text3_1: "② 线上购票： 扫描下方二维码即可购票",
      item4: "票务咨询",
      item5: "支付",
    },
  },
  DetailDialog: {
    item1: "论坛名称",
    item2: "会议地点：",
    item3: "会议时间",
    item4: "课程概要",
    item5: "演讲亮点",
    item6: "演讲嘉宾",
  },
  producerDialog: {
    item1: "姓名",
    item2: "所属公司",
    item3: "公司职位",
    item4: "介绍",
  },

  news: {
    item8: "新闻动态",
    item9: "历届精彩",
  },
  previous: {
    item1: "发展历程",
    item2:
      "中关村智联软件服务业质量创新联盟，适逢我国软件服务业由大做强的关键转折期",
    item3:
      "和云物移大智等技术带来新的变革机遇期， 为有效应对产业做强过程中在质量和创新两大问题上的重大挑战。智联联盟于2013年11月8日，在有关国家部委中关村管委会和海淀园管委会等政府",
    item4:
      " 部门的支持下，由用友、中科软、北京大学以及银行、电信、实践社区等三十多家组织及个人联合发起成立，是国内软件服务业领域首家由来自生态七方联合成立的联盟",
  },
  ICPMainInfo: {
    license: "京ICP备19001603号",
    checkTime: "2019-01-10",
    unitName: "中关村智联软件服务业质量创新联盟",
    unitNature: "社会团体",
  },
  ICPServiceInfo: {
    license: "京ICP备19001603号-1",
    domain: "tid.org.cn",
    phone: "010-53687218",
  },
};
