<template>
  <div>
    <!-- header -->
    <div class="header" style="position: fixed; left: 0; top: 0; background: #fff">
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>

      <div class="header_png">
        <div class="item">
          <router-link to="/login">{{ $t("navbar.item1") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/home">{{ $t("navbar.item2") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/lecture">{{ $t("navbar.item3") }}</router-link>
        </div>
        <div class="item itemActive">
          <router-link to="/previous">{{ $t("navbar.item4") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/authQuery">{{ $t("navbar.item6") }}</router-link>
        </div>
      </div>

      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link to="/ticket" style="
            position: absolute;

            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          ">
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img src="../assets/menpiao.png" alt="" style="
              width: 28rem;
              height: 20rem;
              z-index: 2;
              color: white;
              position: absolute;
              right: 20rem;
              top: 48rem;
            " />
        </div>
      </div>

      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <div class="jing jing_pos" style="margin-top: 115rem">
      <img :src="bgc" alt="" class="jing_img" />
    </div>

    <div style="
        width: 68rem;
        color: rgb(255, 255, 255);
        line-height: 45rem;
        position: absolute;
        top: 345rem;
        right: 41rem;
        position: fixed;
      ">
      <router-link to="/contr" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item8") }}</div>
      </router-link>

      <router-link v-if="false" to="/news" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item1") }}</div>
      </router-link>
      <router-link to="/contributor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/tow.png" alt="" /> -->

        <div>{{ $t("right.item2") }}</div>
      </router-link>
      <router-link to="/sponsor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
        <div>{{ $t("right.item3") }}</div>
      </router-link>
      <router-link to="/ticket" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/fow.png" alt="" /> -->

        <div>{{ $t("right.item4") }}</div>
      </router-link>
      <router-link to="/lecture" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>

        <div>{{ $t("right.item5") }}</div>
      </router-link>
    </div>
    <div class="conter">
      <div style="color: rgb(243, 245, 249); text-align: center">
        Conference Producer
      </div>

      <div class="zhuxi">{{ $t("contr.item1") }}</div>
    </div>

    <div class="conterter">
      <div v-for="presidium in presidiums" class="item1">
        <div class="ruyuan" @click="selectHy(presidium.id)">
          <div class="ruyuan" style="height: 210rem">
            <div style="
                background-color: rgb(255, 255, 255);
                width: 195rem;
                padding-top: 3rem;
                height: 195rem;
                margin-left: 4rem;
                border-radius: 50%;
              ">
              <img :src="presidium.personnel_img_path" alt="" style="
                  width: 188rem;
                  height: 188rem;
                  padding-top: 6rem;
                  border-radius: 50%;
                " />
            </div>
          </div>
          <p style="color: #355ca1; font-size: 22rem">
            {{ presidium.personnel_name }}
          </p>
          <p style="
              color: #355ca1;
              font-size: 16rem;
              line-height: 23rem;
              letter-spacing: 2rem;
              font-family: PingFang SC-Medium;
            " class="shenglve">
            {{ presidium.company }}
          </p>
          <p style="
              color: #355ca1;
              font-size: 16rem;
              line-height: 23rem;
              letter-spacing: 2rem;
              font-family: PingFang SC-Medium;
            " class="shenglve">
            {{ presidium.personnel_position }}
          </p>
        </div>
      </div>
    </div>
    <FooterCom></FooterCom>

    <el-dialog title="" :visible.sync="dialogVisible" width="75%">
      <detail :is_translate="is_translate" :id="selectItem"></detail>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import detail from "@/components/producerDialog.vue";
import FooterCom from "@/components/footerCom.vue";
export default {
  components: {
    detail,
    FooterCom,
  },
  data() {
    return {
      presidiums: null, //主席团
      dialogVisible: false,
      selectItem: null,
      is_translate: false,
      // bgc: "http://123.56.97.68:8080/group1/M00/00/09/rBIAA2ZNZnCASY4nAALfkNzvRlk36.webp"
      bgc: require("../assets/1.png")
    };
  },
  created() {
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }
    this.presidium();
  },
  watch: {
    is_translate() {
      this.presidium();
    },
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },

  methods: {
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
    },
    selectHy(id) {
      this.dialogVisible = true;
      this.selectItem = id;
    },
    // pClick(id){
    // 	this.$router.push({name:'producer',params:{id:id}})
    // },
    //主席团
    presidium() {
      this.$axios(
        "/home/page/presented?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;

          this.presidiums = res.data.data;
        })
        .catch((res) => { });
    },
  },
};
</script>
<style lang="less" scoped>
.tag1 p {
  background-size: cover !important;
}

.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}

.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}

.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}

.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}

.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}

.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}

.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}

.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}

.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}

.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}

.tag1:hover div {
  display: flex;
  background: #1f68eb;
}

.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}

.youqing {
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}

.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  //display: flex;
}

.conterter {
  width: 1280rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.conter {
  width: 1366rem;
  height: 168rem;
  font-size: 81rem;

  //  margin-left: 50%;
  margin: 0 auto;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  margin-top: -86rem;
  text-align: center;
}

.shidai {
  font-size: 20rem;
  line-height: 48rem;
  color: #ffff;
  margin-left: 102rem;
}

// .toum {
// 	margin-top: 165rem;
// 	width: 445rem;
// 	height: 48rem;
// 	background: url(../assets/toum.png);
// 	margin-left: -600rem;
// }
.zhenji {
  padding-top: 130rem;
  font-size: 37rem;
  color: #ffffff;
  margin-left: 63rem;
}

.dahui {
  display: flex;
  font-size: 57rem;
  color: #ffffff;
  margin-left: 462rem;
  padding-top: 115rem;
}

.jing {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  // width: 1920rem;
  // height: 300rem;
  //background: url(../assets/zhubanfang.png);
  position: relative;

  .jing_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.yingwen {
  text-align: center;
}

.header {
  width: 1920rem;
  height: 115rem;
  // background-color: pink;
  display: flex;
}

.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}

.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}

.item:hover {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
  color: red;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  // text-align: center;
}

.item1 {
  width: 188rem;
  margin-right: 176rem;
  margin-bottom: 25rem;
}

.item1:nth-child(4n) {
  margin-right: 0rem;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.ruyuan {
  background: url(../assets/ruyuan.png) no-repeat;
  width: 204rem;
  // border-radius: 50%;
  background-size: 100%;
  padding-top: 2rem;
}

.shenglve {
  /*1. 先强制一行内显示文本*/
  //white-space: nowrap;
  /*2. 超出的部分隐藏*/
  //overflow: hidden;
  /*3. 文字用省略号替代超出的部分*/
  //text-overflow: ellipsis;
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}

.zhenji {
  display: none;
}

.jing_pos {
  position: relative;
  min-height: 300rem;
  width: auto !important;

  .dahui {
    margin-top: 24px;
  }

  .zhenji {
    margin-top: 24px;
  }

  .jing_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}
</style>
