<template>
  <div class="wrap_previous">
    <!-- header -->
    <div class="header" style="position: fixed; left: 0; top: 0; background: #fff">
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>

      <div class="header_png">
        <div class="item">
          <router-link to="/login">{{ $t("navbar.item1") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/home">{{ $t("navbar.item2") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/lecture">{{ $t("navbar.item3") }}</router-link>
        </div>
        <div class="item itemActive">
          <router-link to="/previous">{{ $t("navbar.item4") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/authQuery">{{ $t("navbar.item6") }}</router-link>
        </div>
      </div>

      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link to="/ticket" style="
            position: absolute;

            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          ">
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img src="../assets/menpiao.png" alt="" style="
              width: 28rem;
              height: 20rem;
              z-index: 2;
              color: white;
              position: absolute;
              right: 20rem;
              top: 48rem;
            " />
        </div>
      </div>

      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <div class="jing jing_pos" style="margin-top: 115rem">
      <img :src="base_img" alt="" class="jing_img" />
    </div>

    <!-- 右侧导航栏 -->
    <div style="
        width: 68rem;
        color: rgb(255, 255, 255);
        line-height: 45rem;
        position: absolute;
        top: 345rem;
        right: 41rem;
        position: fixed;
      ">
      <router-link to="/contr" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item8") }}</div>
      </router-link>
      <router-link v-if="false" to="/news" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item1") }}</div>
      </router-link>
      <router-link to="/contributor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/tow.png" alt="" /> -->

        <div>{{ $t("right.item2") }}</div>
      </router-link>
      <router-link to="/sponsor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
        <div>{{ $t("right.item3") }}</div>
      </router-link>
      <router-link to="/ticket" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/fow.png" alt="" /> -->

        <div>{{ $t("right.item4") }}</div>
      </router-link>
      <router-link to="/lecture" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>

        <div>{{ $t("right.item5") }}</div>
      </router-link>
    </div>


    <div class="conter">
      <div style="color: #f3f5f9; line-height: 0.7; height: 0; font-size: 81rem">
        Development History
      </div>
      <div class="zhuxi">{{ $t("previous.item1") }}</div>
    </div>
    <!-- <div class="zhonghuan">{{ $t("previous.item2") }}<br>{{ $t("previous.item3") }}<br>{{ $t("previous.item4") }}。</div> -->

    <div class="xia flex">
      <div v-for="(his, index) in hisLst" class="flex lj">
        <div class="bg"></div>
        <a class="desc" :href="his.cover" target="_target">
          <div v-if="index == 0 || index == 8" class="time">{{ his.year }}</div>
          <div class="content multiSl">{{ his.subject }}</div>
          <div v-if="index != 0 && index != 8" class="time">{{ his.year }}</div>
          <!-- <div class="content">挑战与求变</div>
          <div class="content">挑战与求变</div> -->
        </a>
      </div>

      <div class="feiji"></div>
    </div>
    <footer-com></footer-com>
  </div>
</template>
<script>
import FooterCom from "@/components/footerCom.vue";
export default {
  components: {
    FooterCom,
  },
  data() {
    return {
      is_translate: false,
      days: [], //大会日常天数
      warningList: [],
      base_img: require("../assets/lookBack.jpg"),
      // base_img: "http://123.56.97.68:8080/group1/M00/00/07/rBIAA2WeXOmAZMksAAEP_rifF9g80.webp",
      base_subject: "",
      base_subheading: "",
      base_introduce: "",
      base_time: "",
      base_address: "",
      personnel_name: "",
      personnel_position: "",
      presidiums: null, //主席团
      strategicPartner: null, //战略合作伙伴
      diamondSponsor: null, //钻石赞助商
      platinumSponsor: null, //铂金赞助商
      platinaSponsor: null, //白金赞助商
      financialMedia: null, //独家金融媒体
      partner: null, //合作伙伴
      hyLst: null, //大会日常列表
      index: 0, //大会日常默认下标
      hisLst: null,
    };
  },
  created() {
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }
    this.getHistoryList();
  },
  watch: {
    is_translate() {
      this.getHistoryList();
    },
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },

  methods: {
    overflow(e) {
      if (e.target.innerText == "查看所有") {
        e.target.innerText = "收起";
        let parentBefore = e.target.parentNode.previousSibling;
        parentBefore.style.setProperty("height", "auto");
      } else {
        e.target.innerText = "查看所有";
        let parentBefore = e.target.parentNode.previousSibling;
        parentBefore.style.setProperty("height", "618rem");
      }
    },
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
    },
    getListByDate(selectedDay) {
      this.$axios(
        "home/page/curriculum?is_translate=" +
        this.is_translate +
        "&schedule_time=" +
        selectedDay,
        "get"
      ).then((res) => {
        this.hyLst = res.data.data;
      });
    },
    getHistoryList() {
      this.$axios(
        "history/page/historyList?is_translate=" + this.is_translate,
        "get"
      ).then((res) => {
        this.hisLst = res.data.data;
      });
    },
    dayClick(i) {
      this.index = i;
      let selectedDay = this.days[this.index].schedule_time;
      this.getListByDate(selectedDay);
      // let three = this.times[index].three;
      // for (
      // 	let j = 0;
      // 	index < three.length;
      // 	j++
      // ) {
      // 	this.subjects.push(three[j]);
      // }
    },

    //大会出品人
    getRoleList() {
      this.$axios(
        "/home/page/presented?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          console.log(res);
          this.res = data;
        })
        .catch((res) => { });
    },

    //主题
    sub() {
      this.$axios("/home/page/subject?is_translate=" + this.is_translate, "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);
          // this.res = data
          this.base_subject = data.base_subject;
          this.base_subheading = data.base_subheading;
          this.base_introduce = data.base_introduce;
          this.base_time = data.base_time;
          this.base_address = data.base_address;
          if (data.banner) {
            this.base_img = data.banner;
          }
        })

        .catch((res) => { });
    },
    //主席团
    presidium() {
      this.$axios(
        "/home/page/presidium?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          //console.log(res)
          //this.res = data

          //this.personnel_name = data.personnel_name

          this.presidiums = res.data.data;
        })
        .catch((res) => { });
    },

    //战略合作伙伴
    strategic() {
      this.$axios(
        "/home/page/strategicPartner?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.strategicPartner = res.data.data;
        })
        .catch((res) => { });
    },

    //钻石赞助商
    diamond() {
      this.$axios(
        "/home/page/diamondSponsor?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.diamondSponsor = res.data.data;
        })
        .catch((res) => { });
    },

    //铂金赞助商
    platinum() {
      this.$axios(
        "/home/page/platinumSponsor?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.platinumSponsor = res.data.data;
        })
        .catch((res) => { });
    },

    //白金赞助商
    platina() {
      this.$axios(
        "/home/page/platinaSponsor?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.platinaSponsor = res.data.data;
        })
        .catch((res) => { });
    },

    //独家金融媒体
    financial() {
      this.$axios(
        "/home/page/financialMedia?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          this.financialMedia = res.data.data;
        })
        .catch((res) => { });
    },

    //合作伙伴
    partn() {
      this.$axios("/home/page/partner?is_translate=" + this.is_translate, "get")
        .then((res) => {
          this.partner = res.data.data;
        })
        .catch((res) => { });
    },

    conferenceSchedule2() {
      this.$axios("/home/page/days?is_translate=" + this.is_translate, "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);

          this.days = data;
          this.selectedDay = this.days[this.index].schedule_time;
          //this.times
          this.getListByDate(this.selectedDay);
          // this.base_subject = data.base_subject;
          // this.base_time = data.base_time;
          // this.base_address = data.base_address;
        })
        .catch((res) => { });
    },

    handleNavTo(url) {
      this.$router.push({ name: url })

    }
  },
};
</script>
<style lang="less" scoped>
.multiSl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  margin-top: 71rem;

  &:nth-child(1) {
    margin-top: 0;
  }
}

.flex {
  display: flex;
}

.lj {
  &:nth-child(1) {
    position: absolute;
    left: 257rem;
    top: 196rem;
  }

  &:nth-child(2) {
    position: absolute;
    left: 232rem;
    top: 341rem;
  }

  &:nth-child(3) {
    position: absolute;
    left: 392rem;
    top: 362rem;
  }

  &:nth-child(4) {
    position: absolute;
    left: 556rem;
    top: 352rem;
  }

  &:nth-child(5) {
    position: absolute;
    left: 718rem;
    top: 326rem;
  }

  &:nth-child(6) {
    position: absolute;
    left: 889rem;
    top: 288rem;
  }

  &:nth-child(7) {
    position: absolute;
    left: 1059rem;
    top: 235rem;
  }

  &:nth-child(8) {
    position: absolute;
    left: 1224rem;
    top: 146rem;
  }

  &:nth-child(9) {
    position: absolute;
    left: 1096rem;
    top: 20rem;
  }
}

.feiji {
  background: url(../assets/feiji.png);
  background-size: 100% 100%;
  height: 40rem;
  width: 44rem;
  position: absolute;
  right: 405rem;
  top: 114rem;
}

.bg {
  background: url(../assets/zhuzi.png);
  background-size: 100% 100%;
  height: 200rem;
  width: 15rem;
}

.time {
  color: #4f8bf5;
  font-size: 36rem;
}

.content {
  min-height: 66rem;
  width: 120rem;
  font-size: 16rem;
  font-weight: 500;
  color: #000000;
}

.xia {
  position: relative;
  margin: 88rem auto 0rem;
  width: 1493rem;
  height: 680rem;
  background: url(../assets/diqiu.png);
  background-size: 100% 100%;
  justify-content: space-around;
  z-index: 6;
}

.zhonghuan {
  text-align: center;
  font-size: 18rem;
}

.zhanlue {
  display: flex;
  width: 1304rem;
  margin: auto;
  flex-wrap: wrap;
}

.tag1 p {
  background-size: cover !important;
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}

.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}

.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}

.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}

.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}

.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}

.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}

.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}

.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}

.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}

.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}

.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}

.active::after {
  color: #355ca1;
  content: "";
  // background-color:aqua;
  position: relative;
  display: block;
  top: 42rem;
  left: 5rem;
  width: 0;
  height: 0;
  border: 20rem solid;
  margin: 0 auto;
  border-color: #355ca1 transparent transparent transparent;
}

.riqi.active {
  color: #355ca1;
  //border: 1rem solid #355ca1;
}

.riqi:hover {
  // border-bottom: 2rem solid #355CA1;
  color: #355ca1;
}

.youqing {
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}

.first {
  background: url(../assets/lijie.png);
  display: flex;
  min-height: 300rem;
  //margin-top: 115rem;
  background-size: 100% 100%;
}

.first_pos {
  position: relative;

  .first_img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.item1:last-child {
  margin-right: 0px;
}

.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  // display: flex;
}

.chakan {
  width: 166rem;
  height: 52rem;
  line-height: 52rem;
  display: flex;
  margin: 0 auto;
  margin-top: 18rem;
  border: 1rem solid #496caa;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
}

.chakan img {
  width: 20rem;
  height: 15rem;
  margin-top: 20rem;
  margin-left: 18rem;
}

.fex {
  display: flex;
}

.header {
  width: 1920rem;
  height: 115rem;
  // background-color: pink;
  display: flex;
}

.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}

.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}

.item:hover,
.itemActive {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
}

.item:hover a,
.itemActive a {
  // padding-top: 5rem;
  color: red !important;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.banner {
  width: 1920rem;
  height: 686rem;
  position: absolute;
  /*设为绝对定位*/
}

.conter {
  font-size: 81rem;
  text-align: center;
  margin-top: 60rem;
  margin-bottom: 28rem;
  //margin-top: 700rem;
}

.zhuxi0 {
  color: rgb(243, 245, 249);
  line-height: 0.7;
  height: 0rem;
  font-size: 81rem;
}

.contert {
  width: 1366rem;
  height: 168rem;
  font-size: 81rem;

  margin-left: 17%;
  padding-top: 90rem;
  text-align: center;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  // text-align: center;
}

.item1 {
  width: 167rem;
  margin-right: 176rem;
  margin-bottom: 30rem;
}

.item1:nth-child(4n) {
  margin-right: 0rem;
}

.item2 {
  width: 188rem;
  margin-right: 350rem;
  margin-bottom: 25rem;
}

.item2:nth-child(3n) {
  margin-right: 0rem;
}

.conterter {
  width: 1293rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  // height: 612rem;
  overflow: hidden;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: center;
}

.day {
  display: flex;
  text-align: center;
  // justify-content: space-around;
  color: #aeaeae;
  cursor: pointer;
}

.xian {
  width: 100%;
  border: 1rem solid #355ca1;
  box-sizing: border-box;
}

.zhuanti {
  width: 416rem;
  height: 220rem;
  // border: 1 solid red;
  background-color: rgb(244, 247, 250);
  margin-top: 30rem;
  margin-left: 15rem;
}

.zhuanti1 {
  width: 416rem;
  height: 220rem;
  // border: 1 solid red;
  background-color: rgb(244, 247, 250);
  margin-top: 15rem;
}

.zhuanti img {
  width: 172rem;
  height: 160rem;
}

.shenglve {
  /*1. 先强制一行内显示文本*/
  white-space: nowrap;
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}

// .multiSl2 {
// 	display: -webkit-box;
// 	-webkit-box-orient: vertical;
// 	-webkit-line-clamp: 2;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// }
// .multiSl {
// 	display: -webkit-box;
// 	-webkit-box-orient: vertical;
// 	-webkit-line-clamp: 2;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// }
.ruyuan {
  background: url(../assets/ruyuan.png) no-repeat;
  width: 204rem;
  // border-radius: 50%;
  background-size: 100%;
  padding-top: 2rem;
}

.wrap_previous {
  z-index: 999
}

.header-z {
  z-index: 666;
}

.jing_pos {
  position: relative;
  min-height: 300rem;
  .dahui {
    margin-top: 24px;
  }
  .zhenji {
    margin-top: 24px;
  }
  .jing_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
}
</style>
