<template>
  <div>
    <div class="header" style="position: fixed; left: 0; top: 0; background: #fff">
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>

      <div class="header_png">
        <div class="item">
          <router-link to="/login" class="hove">{{
            $t("navbar.item1")
          }}</router-link>
        </div>
        <div class="item itemActive">
          <router-link to="/home" class="hove">{{
            $t("navbar.item2")
          }}</router-link>
        </div>
        <div class="item">
          <router-link to="/lecture" class="hove">{{
            $t("navbar.item3")
          }}</router-link>
        </div>
        <div class="item">
          <router-link to="/previous" class="hove">{{
            $t("navbar.item4")
          }}</router-link>
        </div>
        <div class="item">
          <router-link to="/authQuery">{{ $t("navbar.item6") }}</router-link>
        </div>
      </div>

      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link to="/ticket" style="
            position: absolute;

            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          ">
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img src="../assets/menpiao.png" alt="" style="
              width: 28rem;
              height: 20rem;
              z-indent: 2;
              color: white;
              position: absolute;
              right: 20rem;
              top: 48rem;
            " />
        </div>
      </div>
      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <div style="
        z-index: 666;
        width: 68rem;
        color: rgb(255, 255, 255);
        line-height: 45rem;
        position: absolute;
        top: 345rem;
        right: 41rem;
        position: fixed;
      ">
      <router-link to="/contr" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item8") }}</div>
      </router-link>
      <router-link v-if="false" to="/news" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item1") }}</div>
      </router-link>
      <router-link to="/contributor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/tow.png" alt="" /> -->

        <div>{{ $t("right.item2") }}</div>
      </router-link>
      <router-link to="/sponsor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
        <div>{{ $t("right.item3") }}</div>
      </router-link>
      <router-link to="/ticket" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/fow.png" alt="" /> -->

        <div>{{ $t("right.item4") }}</div>
      </router-link>
      <router-link to="/lecture" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>

        <div>{{ $t("right.item5") }}</div>
      </router-link>
    </div>
    <!-- :style="{
    background: 'url(' + base_img + ') no-repeat',
    }"-->
    <div class="first first_pos" style="margin-top: 115rem">
      <!-- <img src="../assets/huaban.png" alt="" class="banner" /> -->
      <img :src="bannerSrc" alt="" class="first_img" />
      <!-- <div
				style="
					color: #ffffff;
					letter-spacing: 7rem;
					font-size: 80rem;
					padding-top: 103rem;
				"
			>
				{{ base_subject }}
			</div>

			<div style="font-size: 40rem; color: #ffffff; padding-top: 23rem">
				{{ $t("page2.rcap") }}
			</div>
			<div style="font-size: 40rem; color: #ffffff; padding-top: 102rem">
				{{ base_time }}.{{ base_address }}
			</div> -->
    </div>

    <!-- 大会日程 -->
    <div class="conter">
      <div style="color: #f3f5f9">Conference Schedule</div>

      <div class="zhuxi">{{ $t("page2.dhrc") }}</div>
    </div>

    <div class="day">
      <div style="
          width: 1200rem;
          margin: auto;
          display: flex;
          margin-top: 18rem;
          justify-content: space-around;
        ">
        <div v-for="(dayitem, i) in days">
          <div @click="dayClick(i)" :class="{
            'riqi active': Number(i) == index,
            riqi: Number(i) != index,
          }">
            <div style="font-size: 30rem; margin-top: 10rem">
              {{ dayitem.day }}
              <p style="font-size: 13rem">
                {{ dayitem.schedule_time }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="xian"></div>

      <div>
        <div class="chengcehng">
          <div class="fang" v-if="forum_before.length > 0">
            <div style="font-size: 18rem; color: #ffff; margin-top: 13rem">
              <div style="margin-left: 90rem">
                {{ $t("page2.time2") }}
              </div>
              <div style="margin-right: 95rem; margin-top: 24rem">
                {{ $t("page2.time1") }}
              </div>
            </div>
          </div>
          <div class="fang f1" v-for="s in forum_before">
            <div style="font-size: 18rem; color: #ffff; margin-top: 13rem">
              {{ s.forum_name }}
            </div>
            <!-- <br /> -->
            <div style="font-size: 14rem; color: #ffff; margin-left: 15rem；overflow: hidden;    line-height: 45rem;
">
              {{ $t("page2.time3") }}：{{ s.presented }}
              <!-- 出品人：蓝 -->
            </div>
          </div>
        </div>
        <div v-for="cour in time">
          <div class="chang" v-if="
            cour.conference_time_start < '12:00' ||
            Number(cour.conference_time)
          ">
            <div class="jiegou timeAlign">
              <div style="font-size: 18rem; color: #6a6a6a; text-align: center">
                <p>{{ cour.conference_time }}</p>
                <!-- <p>-</p>
							<p>{{ time.conference_time_end }}</p> -->
              </div>
            </div>
            <div class="jiegou f1" v-for="subItem in forum_before">
              <div v-for="s1 in course_before" @click="dialogVisible = true">
                <div v-if="
                  s1.subject_id == subItem.id &&
                  s1.conference_time == cour.conference_time
                " @click="selectHy(s1.id)">
                  <div style="font-size: 14rem; color: #6a6a6a; text-align: center">
                    {{ s1.conference_subject }}
                  </div>
                  <br />
                  <div style="font-size: 14rem; color: #496caa; text-align: center">
                    {{ s1.lecturer }}
                  </div>
                  <br />
                  <div style="font-size: 14rem; color: #496caa; text-align: center">
                    <!-- 快手 -->
                    {{ s1.company }}
                  </div>
                  <div style="
                      font-size: 14rem;
                      color: #496caa;
                      text-align: center;
                      overflow-wrap: anywhere;
                    ">
                    <!-- 商业化通用技术 组负责人 -->
                    {{ s1.personnel_position }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="chengcehng">
          <div class="fang" v-if="forum_after.length > 0">
            <div style="font-size: 18rem; color: #ffff; margin-top: 13rem">
              <div style="margin-left: 90rem">
                {{ $t("page2.time2") }}
              </div>
              <div style="margin-right: 95rem; margin-top: 24rem">
                {{ $t("page2.time1") }}
              </div>
            </div>
          </div>
          <div class="fang f1" v-for="s in forum_after">
            <div style="font-size: 18rem; color: #ffff; margin-top: 13rem">
              {{ s.forum_name }}
            </div>
            <!-- <br /> -->
            <div style="font-size: 14rem; color: #ffff; margin-left: 15rem；overflow: hidden;    line-height: 45rem;
">
              {{ $t("page2.time3") }}：{{ s.presented }}
              <!-- 出品人：蓝 -->
            </div>
          </div>
        </div>
        <div v-for="cour in time">
          <div class="chang" v-if="cour.conference_time_start >= '12:00'">
            111
            <div class="jiegou timeAlign">
              <div style="font-size: 18rem; color: #6a6a6a; text-align: center">
                <p>{{ cour.conference_time }}</p>
                <!-- <p>-</p>
							<p>{{ time.conference_time_end }}</p> -->
              </div>
            </div>
            <div class="jiegou f1" v-for="subItem in forum_after">
              <div v-for="s1 in course_after" @click="dialogVisible = true">
                <div class="jiegou_flex" v-if="
                  s1.subject_id == subItem.id &&
                  s1.conference_time == cour.conference_time
                " @click="selectHy(s1.id)">
                  <div style="font-size: 14rem; color: #6a6a6a; text-align: center">
                    {{ s1.conference_subject }}
                  </div>
                  <br />
                  <div style="font-size: 14rem; color: #496caa; text-align: center">
                    {{ s1.lecturer }}
                  </div>
                  <br />
                  <div style="font-size: 14rem; color: #496caa; text-align: center">
                    <!-- 快手 -->
                    {{ s1.company }}
                  </div>
                  <div style="
                      font-size: 14rem;
                      color: #496caa;
                      margin-left: 15rem;
                      overflow-wrap: anywhere;
                    ">
                    <!-- 商业化通用技术 组负责人 -->
                    {{ s1.personnel_position }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterCom />

    <el-dialog title="" :before-close="dialogVisible" :visible.sync="dialogVisible" :show-close="false" width="75%">
      <detail :id="selectItem" :is_translate="is_translate"></detail>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
var self;
import detail from "@/components/DetailDialog.vue";
import FooterCom from "@/components/footerCom.vue";
export default {
  components: {
    detail,
    FooterCom,
  },
  data() {
    return {
      base_img: require("../assets/huaban.png"),
      defaultValue: 1,
      conference_time_start: "",
      base_subject: "",
      base_time: "",
      base_address: "",
      scheduleData: null,
      days: [],
      time: [],
      time1: [],
      course_before: [],
      course_aftere: [],
      forum_before: [],
      forum_after: [],
      forum: [],
      course: [],
      index: 0,
      dialogVisible: false,
      selectItem: null,
      is_translate: false,
      bgc: require("../assets/2.png"),
      bannerSrc: "",
    };
  },
  created() {
    self = this;
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }
    this.conferenceSchedule();
    this.getDays();
    this.getBannerSrc();
  },
  watch: {
    is_translate() {
      this.course = [];
      this.conferenceSchedule();
      this.getDays();
    },
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },

  methods: {
    getBannerSrc() {
      this.$axios("home/page/getBanner", "get").then((res) => {
        this.bannerSrc = res.data.data.schedule_banner;
      });
    },
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
    },
    selectHy(id) {
      this.selectItem = id;
    },
    handleClose(done) {
      done();
    },
    dayClick(i) {
      this.index = i;
      this.course = [];
      this.getScheduleByDate(this.days[i].schedule_date);
    },
    //主题
    conferenceSchedule() {
      this.$axios(
        "/schedule/page/scheduleTop?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          console.log(res);

          this.base_subject = data.base_subject;
          this.base_time = data.base_time;
          this.base_address = data.base_address;
          this.base_address = data.base_address;
          if (data.banner) {
            this.base_img = data.banner;
          }
        })
        .catch((res) => { });
    },
    //
    getScheduleByDate(date) {
      this.$axios(
        "/schedule/page/curriculum?is_translate=" +
        this.is_translate +
        "&schedule_time=" +
        date,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          this.time = data.course;
          this.forum = data.forum;

          this.course = [];
          this.course_before = [];
          this.course_after = [];
          this.forum_before = [];
          this.forum_after = [];

          for (let index = 0; index < this.time.length; index++) {
            let thr = this.time[index].three;
            for (let j = 0; j < thr.length; j++) {
              if (Number(this.time[index].conference_time)) {
                // alert(1)
                this.course_before.push(thr[j]);
                this.forum_before = data.forum;
                // break;
                continue;
              }

              if (this.time[index].conference_time_start) {
                if (this.time[index].conference_time_start < "12:00") {
                  this.course_before.push(thr[j]);
                  for (let k = 0; k < this.forum.length; k++) {
                    if (this.forum[k].id == thr[j].subject_id) {
                      let find = false;
                      for (let l = 0; l < this.forum_before.length; l++) {
                        if (this.forum_before[l].id == thr[j].subject_id) {
                          find = true;
                          break;
                        }
                      }
                      if (!find) {
                        this.forum_before.push(this.forum[k]);
                      }
                    }
                  }
                }
                if (this.time[index].conference_time_start >= "12:00") {
                  this.course_after.push(thr[j]);
                  for (let k = 0; k < this.forum.length; k++) {
                    if (this.forum[k].id == thr[j].subject_id) {
                      let find = false;
                      for (let l = 0; l < this.forum_after.length; l++) {
                        if (this.forum_after[l].id == thr[j].subject_id) {
                          find = true;
                          break;
                        }
                      }
                      if (!find) {
                        this.forum_after.push(this.forum[k]);
                      }
                    }
                  }
                }
              }
            }
          }
        })
        .catch((res) => { });
    },
    getDays() {
      //获取时间并且带上第一天的参数 请求第一天的数据
      this.$axios(
        "/schedule/page/days?is_translate=" + this.is_translate,
        "get"
      )
        .then((res) => {
          let data = res.data.data;
          this.days = data;
          if (data) {
            this.selectedDay = this.days[this.index];
            let firstDay = this.selectedDay.schedule_date;
            this.getScheduleByDate(firstDay);
          }
        })
        .catch((res) => { });
    },
  },
};
</script>
<style lang="less" scoped>
.timeAlign {
  align-items: center;
}

.f1 {
  flex: 1;
  justify-content: center !important;
}

.tag1 p {
  background-size: cover !important;
}

.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}

.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}

.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}

.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}

.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}

.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}

.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}

.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}

.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}

.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}

.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}

.active::after {
  color: #355ca1;
  content: "";
  // background-color:aqua;
  position: absolute;
  display: block;
  top: 109rem;
  left: 106rem;
  width: 0;
  height: 0;
  border: 20rem solid;
  margin: 0 auto;
  border-color: #355ca1 transparent transparent transparent;
}

.riqi.active {
  color: #355ca1;
  border: 1rem solid #355ca1;
  position: relative;
}

.riqi:hover {
  // border-bottom: 2rem solid #355CA1;
  color: #355ca1;
}

.youqing {
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}

.first {
  background: url(../assets/huaban.png);
  background-size: 100% 100% !important;
  text-align: center;
  //height: 605rem;
  height: 300rem;
}

.first_pos {
  width: 100vw;
  //min-height: 686rem;
  //height: 300rem;
  position: relative;

  .first_img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    //object-fit: cover;
  }
}

.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  // display: flex;
}

.chakan {
  width: 166rem;
  height: 52rem;
  line-height: 52rem;
  display: flex;
  margin: 0 auto;
  margin-top: 18rem;
  border: 1rem solid #496caa;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
}

.chakan img {
  width: 20rem;
  height: 15rem;
  margin-top: 20rem;
  margin-left: 18rem;
}

.chengcehng {
  display: flex;
  width: 1393rem;
  min-height: 88rem;
  margin: 0 auto;
  margin-top: 46rem;
  cursor: pointer;
}

.chang {
  display: flex;
  width: 1390rem;
  //height: 260rem;
  margin: 0 auto;
  background-color: #f0f3f8;
  //border: 1rem solid #355ca1;
}

.chang1 {
  display: flex;
  width: 1390rem;
  height: 260rem;
  margin: 0 auto;
  background-color: #f8fafc;
  //border: 1rem solid #355ca1;
}

.fang {
  width: 141rem;
  border: 1rem solid #ffffff;
  background-color: #355ca1;
  text-align: center;
}

.jiegou {
  width: 141rem;
  //height: 260rem;
  border: 1rem solid #ffff;
  //background-color:red;
  cursor: pointer;
  // min-height: 200rem;
  // align-items: center;
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 10rem 0;
}

.riqi {
  width: 243rem;
  height: 88rem;
  border: 1rem solid #aaa;
}

.fex {
  display: flex;
}

.header {
  width: 1920rem;
  height: 115rem;
  // background-color: pink;
  display: flex;
  z-index: 11;
}

.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}

.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}

.item:hover {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
  color: red;
}

.hove:hover {
  color: red;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.banner {
  width: 1920rem;
  height: 686rem;
  position: absolute;
  /*设为绝对定位*/
}

.conter {
  // width: 1366rem;
  //height: 168rem;
  min-height: 80rem;
  font-size: 81rem;
  // margin-left: 17%;
  text-align: center;
  margin-top: 24rem;
  margin-bottom: 30rem;
}

.contert {
  width: 1366rem;
  height: 168rem;
  font-size: 81rem;

  // margin-left: 17%;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  margin-top: -86rem;
  // margin-left: 35%;
}

.day {
  display: flex;
  text-align: center;
  // justify-content: space-around;
  color: #aeaeae;
  margin-top: -20rem;
  cursor: pointer;
}

.xian {
  width: 100%;
  border: 1rem solid #355ca1;
  margin-top: 18rem;
  box-sizing: border-box;
}

.itemActive {
  // padding-top: 5rem;
  border-bottom: 2px solid red;
}

.itemActive a {
  // padding-top: 5rem;
  color: red !important;
}

.jiegou:hover {
  background-color: gainsboro;
}

.foot {
  height: auto;
}

.jiegou_flex {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
</style>
