<template>
  <div>
    <!-- header -->
    <div class="header" style="position: fixed; left: 0; top: 0; background: #fff">
      <div><img src="../assets/toubu.png" alt="" class="imger" /></div>

      <div class="header_png">
        <div class="item">
          <router-link to="/login">{{ $t("navbar.item1") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/home">{{ $t("navbar.item2") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/lecture">{{ $t("navbar.item3") }}</router-link>
        </div>
        <div class="item itemActive">
          <router-link to="/previous">{{ $t("navbar.item4") }}</router-link>
        </div>
        <div class="item">
          <router-link to="/authQuery">{{ $t("navbar.item6") }}</router-link>
        </div>
      </div>

      <div style="position: relative">
        <img src="../assets/juxing.png" alt="" class="juxingimg" />
        <router-link to="/ticket" style="
            position: absolute;
            top: 45rem;
            right: 70rem;
            color: white;
            font-size: 16rem;
          ">
          {{ $t("navbar.item5") }}
        </router-link>
        <div>
          <img src="../assets/menpiao.png" alt="" style="
              width: 28rem;
              height: 20rem;
              z-index: 2;
              color: white;
              position: absolute;
              right: 20rem;
              top: 48rem;
            " />
        </div>
      </div>

      <a class="biankuang" v-if="!is_translate" @click="changeLang(true)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          English
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
      <a class="biankuang" v-else @click="changeLang(false)">
        <div style="
            margin-top: 8rem;
            font-size: 18rem;
            margin-left: 21rem;
            width: 61rem;
            color: #242424;
          ">
          China
        </div>
        <img src="../assets/yuyan.png" alt="" class="yuyanimg" />
      </a>
    </div>

    <div class="jing jing_pos" style="margin-top: 115rem">
      <img :src="bgc" alt="" class="jing_img" />
    </div>

    <div style="
        width: 68rem;
        color: rgb(255, 255, 255);
        line-height: 45rem;
        position: absolute;
        top: 345rem;
        right: 41rem;
        position: fixed;
      ">
      <router-link to="/contr" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item8") }}</div>
      </router-link>
      <router-link v-if="false" to="/news" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/onw.png" alt="" /> -->
        <div>{{ $t("right.item1") }}</div>
      </router-link>
      <router-link to="/contributor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/tow.png" alt="" /> -->

        <div>{{ $t("right.item2") }}</div>
      </router-link>
      <router-link to="/sponsor" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img
					src="../assets/sow.png"
					alt=""
					style="width: 68rem; height: 68rem"
				/> -->
        <div>{{ $t("right.item3") }}</div>
      </router-link>
      <router-link to="/ticket" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>
        <!-- <img src="../assets/fow.png" alt="" /> -->

        <div>{{ $t("right.item4") }}</div>
      </router-link>
      <router-link to="/lecture" class="tag1" style="position: relative; display: block">
        <p style="width: 68rem; height: 68rem"></p>

        <div>{{ $t("right.item5") }}</div>
      </router-link>
    </div>
    <div class="conter">
      <div style="color: rgb(243, 245, 249); text-align: center">
        About the Sponsor
      </div>

      <div class="zhuxi">{{ $t("sponsor.item1") }}</div>
    </div>

    <div style="display: flex">
      <div class="zhilian">
        <img src="../assets/zhilian.png" alt="" style="width: 100%; height: 100%" />
      </div>

      <div style="flex: 1">
        <div class="zhongguan">{{ $t("sponsor.item2") }}</div>
        <div class="con">
          {{ $t("sponsor.item3") }}
        </div>
      </div>
    </div>

    <div class="wrap_footer">
      <FooterCom></FooterCom>
      <!-- <div class="info_main">
        <div class="item_info">
          <div style="font-size: 14rem; margin-top: 42rem">
            {{ $t("bottom.item1.item1") }}
          </div>
          <div style="
            font-size: 12rem;
            color: #706f6f;
            margin-top: 8rem;
          ">
            {{ $t("bottom.item1.relationPhone") }}
          </div>
          <div style="
            font-size: 12rem;
            color: #706f6f;
            margin-top: 9rem;
          ">
            {{ $t("bottom.item1.relationPhone1") }}
          </div>
        </div>

        <div class="item_info">
          <div style="font-size: 14rem; margin-top: 42rem">
            {{ $t("ICPMainInfo.license") }}
          </div>

          <div style="
            font-size: 12rem;
            color: #706f6f;
            margin-top: 8rem;
          ">
            {{ $t("ICPMainInfo.checkTime") }}
          </div>

          <div style="
            font-size: 12rem;
            color: #706f6f;
            margin-top: 9rem;
          ">
            {{ $t("ICPMainInfo.unitName") }}
          </div>

          <div style="
            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          ">
            {{ $t("ICPMainInfo.unitNature") }}
          </div>
        </div>

        <div class="item_info">
          <div style="font-size: 14rem; margin-top: 42rem">
            {{ $t("ICPServiceInfo.license") }}
          </div>

          <div style="
            font-size: 12rem;
            color: #706f6f;
            margin-top: 8rem;
          ">
            {{ $t("ICPServiceInfo.domain") }}
          </div>

          <div style="
            font-size: 12rem;
            color: #706f6f;
            margin-top: 9rem;
          ">
            {{ $t("ICPServiceInfo.phone") }}
          </div>
        </div>
      </div>
      <div class="code_main">
        <div class="item_code">
          <div style="
            font-size: 14rem;
            margin-top: 24rem;

          ">
            <img src="../assets/si.jpg" alt="" style="width: 100rem; height: 100rem" />
          </div>

          <div style="
            font-size: 14rem;
            color: #706f6f;
          ">
            {{ $t("bottom.item8") }}
          </div>
        </div>
        <div class="item_code">
          <div style="

            font-size: 14rem;
            margin-top: 24rem;

          ">
            <img src="../assets/wu.jpg" alt="" style="width: 100rem; height: 100rem" />
          </div>
          <div style="

            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          ">
            {{ $t("bottom.item9") }}
          </div>
        </div>
        <div class="item_code">
          <div style="

            font-size: 14rem;
            margin-top: 24rem;

          ">
            <img src="../assets/erweima.png" alt="" style="width: 100rem; height: 100rem" />
          </div>
          <div style="

            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          ">
            {{ $t("bottom.item4") }}
          </div>
        </div>
        <div class="item_code">
          <div style="

            font-size: 14rem;
            margin-top: 24rem;

          ">
            <img src="../assets/eriweimm.png" alt="" style="width: 100rem; height: 100rem" />
          </div>

          <div style="

            font-size: 14rem;
            color: #706f6f;
            margin-top: 12rem;
          ">
            {{ $t("bottom.item5") }}
          </div>
        </div>
      </div> -->
    </div>

  </div>
</template>
<script>
import FooterCom from "@/components/footerCom.vue";
export default {
  components: {
    FooterCom,
  },
  data() {
    return {
      is_translate: false,
      bgc: require("../assets/1.png")
    };
  },

  created() {
    if (sessionStorage.getItem("language") == "en") {
      this.is_translate = true;
    }

    this.subject();
  },
  watch: {
    is_translate() {
      this.subject();
    },
  },
  computed: {
    lang: {
      get() {
        return sessionStorage.getItem("language");
      },
      set(lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("language", lang);
      },
    },
  },
  methods: {
    changeLang(is_translate) {
      this.is_translate = is_translate;
      if (is_translate) {
        this.lang = "en";
      } else {
        this.lang = "zh";
      }
    },
    //主题
    subject() {
      this.$axios("/home/page/subject", "get")
        .then((res) => {
          let data = res.data.data;
          console.log(res);

          // this.base_subject = data.base_subject;
          // this.base_time = data.base_time;
          // this.base_address = data.base_address;
        })
        .catch((res) => { });
    },
  },
};
</script>
<style lang="less" scoped>
.tag1 p {
  background-size: cover !important;
}

.tag1:nth-child(1):hover p {
  background: url(../assets/onw1.png);
}

.tag1:nth-child(2):hover p {
  background: url(../assets/tow1.png);
}

.tag1:nth-child(3):hover p {
  background: url(../assets/sow1.png);
}

.tag1:nth-child(4):hover p {
  background: url(../assets/fow1.png);
}

.tag1:nth-child(5):hover p {
  background: url(../assets/sov1.png);
}

.tag1:nth-child(1) p {
  background: url(../assets/onw.png);
  background-size: cover;
}

.tag1:nth-child(2) p {
  background: url(../assets/tow.png);
  background-size: cover;
}

.tag1:nth-child(3) p {
  background: url(../assets/sow.png);
  background-size: cover;
}

.tag1:nth-child(4) p {
  background: url(../assets/fow.png);
  background-size: cover;
}

.tag1:nth-child(5) p {
  background: url(../assets/sov.png);
  background-size: cover;
}

.tag1:hover div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #1f68eb;
  line-height: 24px;
  padding-left: 8px;
}

.tag1 div {
  display: none;
  color: white;
  width: 205rem;
  height: 68rem;
  background: rgb(188, 201, 232);
  position: absolute;
  right: 0rem;
  z-index: -1;
  top: 0;
  border-radius: 50rem;
  text-align: center;
  box-sizing: border-box;
  display: none;
  justify-content: center;

  padding: 0rem 70rem 0rem 0rem;
  box-sizing: border-box;
  align-items: center;
}

.youqing {
  font-size: 12rem;
  display: flex;
  margin-left: 324rem;
  color: #706f6f;
}

.youqing1 {
  font-size: 12rem;
  display: flex;
  margin-left: 869rem;
  // color: #706f6f;
  margin-top: 54rem;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.foot {
  width: 100%;
  height: 360rem;
  background-color: #f2f2f2;
  margin-top: 90rem;
  //display: flex;
  // 	width: 100%;
  // height: 360rem;
  // background-color: #f2f2f2;
  // margin-top: 90rem;
}

.con {
  width: 1000rem;
  color: #355ca1;
  font-size: 16rem;
  padding-top: 34rem;
  text-align: justify;
  //text-align-last:justify;
}

.zhongguan {
  font-size: 42rem;
  color: #355ca1;
}

.zhilian {
  width: 337rem;
  height: 332rem;
  margin-left: 311rem;
  background-size: 100%;
}

.header {
  width: 1920rem;
  height: 115rem;

  display: flex;
}

.imger {
  width: 116rem;
  height: 79rem;
  margin-left: 321rem;
  margin-top: 18rem;
}

.header_png {
  width: 757rem;
  justify-content: space-around;
  display: flex;
  margin-top: 45rem;
  font-size: 20rem;
  cursor: pointer;
}

.item:hover {
  border-bottom: 2px solid red;
  color: red;
}

.biankuang {
  width: 139rem;
  height: 41rem;
  margin-top: 37rem;
  margin-left: 131rem;
  background-color: #f2f2f2;
  border-radius: 20rem 20rem 20rem 20rem;
  display: flex;
}

.juxingimg {
  width: 139rem;
  height: 41rem;
  margin-left: 88rem;
  margin-top: 37rem;
}

.jing {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  //background: url(../assets/zhubanfang.png);
  position: relative;

  .jing_img {
    //background-size: 50% 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.dahui {
  display: flex;
  font-size: 57rem;
  color: #ffffff;
  margin-left: 553rem;
  padding-top: 44rem;
}

.zhenji {
  padding-top: 58rem;
  font-size: 37rem;
  color: #ffffff;
  margin-left: 63rem;
}

.toum {
  margin-top: 165rem;
  width: 445rem;
  height: 48rem;
  background: url(../assets/toum.png);
  margin-left: -440rem;
}

.shidai {
  font-size: 20rem;
  line-height: 48rem;
  color: #ffff;
  margin-left: 102rem;
  display: none;
}

.conter {
  width: 1366rem;
  height: 168rem;
  font-size: 81rem;

  //  margin-left: 50%;
  margin: 0 auto;
}

.zhuxi {
  color: #355ca1;
  font-size: 50rem;
  margin-top: -86rem;
  text-align: center;
}

.yuyanimg {
  margin-left: 8rem;
  width: 25rem;
  height: 25rem;
  margin-top: 7rem;
}

.jing_pos {
  position: relative;
  min-height: 300rem;
  width: auto !important;

  .dahui {
    margin-top: 24px;
  }

  .zhenji {
    margin-top: 24px;
  }

  .jing_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

.wrap_footer {
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .foot {
    margin-top: 0 !important;
  }

  .info_main {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 120rem;

    .item_info {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 24px;
    }
  }

  .code_main {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 88rem;
    margin-bottom: 24px;

    .item_code {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 4rem;
    }
  }
}
</style>
